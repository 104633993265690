<template>
    <div class="contact-container">
        <div class="contact-main-container">
            <div class="left-container">
                <div class="header">
                    <p>İLETİŞİME GEÇ</p>
                </div>
                <div class="input-container">
                    <div class="input-header">
                        <p>Ad Soyad</p>
                    </div>
                    <div class="text-container">
                        <i class="fa-solid fa-user"></i>
                        <input type="text" required v-model="name">
                    </div>
                </div>

                <div class="input-container">
                    <div class="input-header">
                        <p>E-Posta</p>
                    </div>
                    <div class="text-container">
                        <i class="fa-solid fa-envelope"></i>
                        <input type="text" required v-model="email">
                    </div>
                </div>


                <div class="input-container">
                    <div class="input-header">
                        <p>Mesajınız</p>
                    </div>
                    <textarea name="" id="" cols="30" rows="5" required v-model="message"></textarea>
                </div>

                <div class="send-button" @click="sendEmail">
                    <p>GÖNDER</p>
                </div>
            </div>

            <div class="right-container">
                <div class="text-container">
                    <div class="title">
                        <p>MAİL ADRESİMİZ</p>
                    </div>
                    <p>support@hypnosegamestudio.com</p>
                </div>
               
                <div class="text-container">
                    <div class="title">
                        <p>ADRES</p>
                    </div>
                    <p></p>
                </div>

            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            
            name : '',
            email : '',
            message : '',


        };
    },
    
    methods: {
        sendEmail() {
            if(this.name == '' || this.email == '' || this.message == '') {
                console.log('Lütfen tüm alanları doldurunuz.');
                return;
            }

            let data = {
                name : this.name,
                email : this.email,
                message : this.message
            }
            this.$socket.emit('sendSupportMail', data);
            
            this.name = '';
            this.email = '';
            this.message = '';
            console.log('sended', data)
        }
    }
};
</script>

<style lang="scss" scoped>

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    padding: 1.5vh;
    .contact-main-container {
        display: flex;
        // width: 70vw;
        // align-items: center;
        // justify-content: space-between;
        justify-content: center;
        background: rgba(0, 0, 0, 0.31);
        padding: 5vh;
        padding-top: 4vh;
        padding-inline: 3vh;
        box-shadow: 0px 0px 24.7px 0px rgba(255, 199, 0, 0.13);
        border-radius: 0.3125rem;
        gap: 4vh;

        

        .left-container {
            display: flex;
            flex-direction: column;

            height: 100%;

            .header {
                display: flex;
                margin-bottom: 1vw;
                p {
                    font-family: 'Outfit-Bold';
                    font-size: calc(1rem + 1vw);

                    color: #FFFFFF;
                    
                    white-space: nowrap;
                }
            }
            .input-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 1.5vh;
                .input-header {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 3vh;
                    p {
                        font-family: 'Outfit-Bold';
                        font-size: calc(0.5rem + 0.5vw);

                        color: #FFFFFF;
                        text-transform: uppercase;
                    }
                }

                .text-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 5vh;
                    background: rgba(0, 0, 0, 0.25);
                    padding-inline: 1.5vh;

                    i {
                        margin-right: 1vh;
                        font-size: 1.5vh;

                        color: rgba(255, 199, 0, 1);
                    }

                    input {
                        width: 100%;
                        height: 100%;
                        background: transparent;
                        border: none;
                        outline: none;
                        font-family: 'Outfit-Medium';
                        font-size: 2vh;
                        
                        color: #FFFFFF;
                    }
                }

                textarea {
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.25);
                    border: none;
                    outline: none;
                    font-family: 'Outfit-Medium';
                    font-size: 2vh;
                    padding: 1vh;

                    color: #FFFFFF;
                }
            }

            .send-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 5vh;
                background: #dbac01;
                cursor: pointer;

                transition: transform 250ms ease-in;

                p {
                    font-family: 'Outfit-Bold';
                    font-size: 2vh;
                    
                    color: #FFFFFF;
                }
            }

            .send-button:hover {
                transform: scale(1.01);
            }
        }

        .right-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            .text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                
                .title {
                    display: flex;

                    p {
                        font-family: 'Outfit-Bold';
                        font-size: calc(10px + 0.390625vw);


                        color: rgba(255, 199, 0, 1);
                        text-transform: uppercase;
                    }
                }

                p {
                    font-family: 'Outfit-Medium';
                    font-size: calc(8px + 0.390625vw);

                    color: #FFFFFF;
                }
            }

           
        }
    }

}
</style>