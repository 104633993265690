import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import SignUp from '../views/SignUp.vue';
import Login from '../views/Login.vue';
import Profile from '../views/Profile.vue';
import AboutView from '../views/AboutView.vue';
import Contact from '../views/Contact.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'Hypnose Games' },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUp,
    meta: { title: 'Hypnose Games - Sign Up' },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { title: 'Hypnose Games - Login' },
  },

  {
    path: '/profile/:username',
    name: 'userProfile',
    component: Profile,
    meta: { title: 'Hypnose Games - UserProfile' },
  },

  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { title: 'Hypnose Games - About' },
  },

  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: { title: 'Hypnose Games - contact Us' },
  }

  // Lazy-loading için ayrılmış bir "about" rotası örneği
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;