<template>
    <div class="signup-container">
        <div class="signup-center-container">
            <p class="title">KAYIT OL</p>
            <div class="inline">
                <div class="column">
                    <p>Ad</p>
                    <input type="text" placeholder="Ad" v-model="firstname" />
                </div>
                <div class="column">
                    <p>Soyad</p>
                    <input type="text" placeholder="Soyad" v-model="lastname" />
                </div>
            </div>
            <div class="column">
                <p>E-MAIL</p>
                <input type="text" placeholder="Email" v-model="email" />
            </div>
            <div class="column">
                <p>Kullanıcı Adı</p>
                <input type="text" placeholder="Kullanıcı adı" v-model="username" />
            </div>
            <div class="column">
                <p>Şifre</p>
                <input type="password" placeholder="Şifre" v-model="password" />
            </div>
            <div class="column">
                <p>Şifre (tekrar)</p>
                <input type="password" placeholder="Şifre (tekrar)" v-model="password_repeat" />
            </div>

            <div class="inline">
                <div class="inline-column">
                    <p>Cinsiyet</p>
                    <select name="gender" id="gender" v-model="gender">
                        <option value="0">Erkek</option>
                        <option value="1">Kadın</option>
                        <option value="2">Diğer</option>
                    </select>
                    <!-- <v-select class="style-chooser" :options="genders" label="title"></v-select> -->
                </div>
                <div class="inline-column">
                    <p>Yaş</p>
                    <input type="number" v-model="age" placeholder="Yaş" min="0" max="99">
                </div>

            </div>

            
            <div class="signup-button" @click="signUp"> KAYIT OL </div>
            <transition name="fade">
                <p v-if="msg">{{ msg }}</p>
            </transition>
        </div>
        
    </div>
</template>

<style lang="scss" scoped>
   .fade-enter-active {
        transition: opacity 0.5s ease-in-out;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-enter{
        opacity: 0;
    }
    .signup-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100vh;

        .signup-center-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 1vh;
            padding: 3vh;
            background: rgba(0, 0, 0, 0.35);
            border-radius: 1vh;

            .title {
                font-family: "Outfit-Bold";
                font-size: 4vh;
                color: #FFC700;
                margin-bottom: 1vh;
                text-align: center;
            }

            input {
                background: rgba(0, 0, 0, 0.35);
                outline: none;
                border: 0;
                padding: 1vh;
                color: #FFFFFF;
                font-family: "Outfit-Regular";
                width: 100%;
                font-size: 1.5vh;
            }

            .inline {
                display: flex;
                gap: 1vh;
                select {
                    background: rgba(0, 0, 0, 0.35);
                    color: #FFFFFF;
                    font-family: "Outfit-Medium";

                    border: none !important; /* Border'ı kaldır */
                    outline: none !important;
                    scroll-behavior: smooth !important;
                    padding: 1vh;

                }
                select:focus,  select:hover {
                    border: none;
                    outline: none;
                    scroll-behavior: smooth;
                }

                .inline-column {
                    display: flex;
                    flex-direction: column;
                    gap: 0.25vh;
                    width: 100%;
                }
            }
            .column {
                display: flex;
                flex-direction: column;
                gap: 0.25vh;

                p {
                    font-family: "Outfit-Medium";
                    color: #FFFFFF;
                    text-transform: uppercase;
                    font-size: 1.5vh;
                }
            }

            .signup-button {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1vh;
                padding-inline: 1.5vh;
                margin-inline: auto;
                margin-top: 1.5vh;
                background: #FFC700;
                border-radius: 0.5vh;
                color: #000000;
                font-family: "Outfit-ExtraBold";
                transition: 0.15s ease-in-out;
            }
            .signup-button ~ p {
                font-family: "Outfit-Bold";
                font-size: 1.75vh;
                margin-inline: auto;
                color: 	#ff3333;
            }
            .signup-button:hover {
                background: #FFC700;
                color: #FFFFFF;
            }
        }
    }
</style>

<script>
import AuthService from '@/services/AuthService.js';

export default {
    data() {
        return {
            firstname: '',
            lastname: '',
            email: '',
            gender: '',
            age: '',

            username: '',
            password: '',
            password_repeat: '',
            msg: '',

        };
    },
    methods: {
        async signUp() {
            try {
                const credentials = {
                    username: this.username,
                    password: this.password,
                    password_repeat: this.password_repeat,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    age: this.age,
                    gender: this.gender
                };
                const response = await AuthService.signUp(credentials);
                this.msg = response.msg;

                try {
                    const response = await AuthService.login({
                        username: this.username,
                        password: this.password
                    });
                    const token = response.token;
                    const user = response.user;
                    this.$store.dispatch('login', { token, user });
                    setTimeout(() => {
                        this.$router.push('/');
                        this.msg = ''
                    }, 3000);
                } catch (error) {
                    this.msg = error.response.data.msg;
                }
            } catch (error) {
                this.msg = error.response.data.msg;
                setTimeout(() => {
                    this.msg = ''
                }, 4000);
            }
        }
    }
};
</script>