<template>
    <div class="profile-main-container">
        <div class="profile-popup-container" v-if="editPicture">
            <div class="profile-popup">
                <div class="close" @click="editPicture = false">
                    <i class="fas fa-times"></i>
                </div>
                <p>Fotoğraf linki girin.</p>
                <input type="text" name="" id="" placeholder="Fotoğraf URL" spellcheck="false" v-model="pictureLink">
                <div class="buttons">
                    <button @click="editPicture = false">İptal</button>
                    <button @click="savePicture">Kaydet</button>
                </div>
            </div>

        </div>
        <div class="profile-center">
            <p class="title">PROFILIM</p>

            <div class="profile-top-container" :style="{
                'background-image': `url(${user?.backgroundPicture})`
            }">
                <div class="background-picture-button" v-if="isAbleOpen()" @click="editPicture = 'background'">
                    <i class="fas fa-user-edit"></i>
                </div>
                <div class="top-header">
                    <div class="trophy">
                        <img src="https://assets.hypnosegames.com/images/trophy/beta.png" alt="">
                    </div>

                    <div class="search-bar">
                        <i class="fas fa-search"></i>
                        <input type="text" v-model="searchText" placeholder="Kişi ara" @keyup.enter="search" spellcheck="false">
                    </div>
                </div>


                <div class="profile-top-inner-container" v-if="user">
                    <div class="image-container">
                        <div class="edit-picture-button" v-if="isAbleOpen()" @click="editPicture = 'profile'">
                            <i class="fas fa-user-edit"></i>
                        </div>
                        <img :src="user.profilePicture" alt="">
                        <!-- <img src="https://assets.hypnosegames.com/images/profile-photo.jpg" alt=""> -->
                    </div>
                    <div class="text-container">
                        <div class="name">
                            <p>{{user.firstname + ' "' + user.username + '" ' + user.lastname }}</p>
                            <i class="fas fa-envelope" :class="{'verified': user.isVerified}"></i>
                            <img src="https://assets.hypnosegames.com/images/verified.png" alt="">
                        </div>
                        <p>{{$store.state.groups[user.group] }}</p>
                        <p>{{ calculateDate(user) + ' tarihinde katıldı' }}</p>

                        <div class="add-friend-button" @click="addFriend" v-if="$store.getters.isLoggedIn && $store.getters.getUser.username != user.username">
                            <i class="fa-solid fa-user-plus"></i>
                        </div>
                    </div>

                </div>
            </div>

            <div class="profile-middle-container" v-if="user">
                <div class="friend-list-container">
                    <div class="friend-list-header">
                        <p>Arkadaşlarım</p>
                    </div>
                    <div class="friend-list" >
                        <div class="friend" v-for="item, key, index in user.friends" :key="index" @click="onClickFriend(item.username)">
                            <div class="friend-image">
                                <img :src="item.profilePicture" alt="">
                                <!-- <img :src="`https://assets.hypnosegames.com/images/profile-photo.jpg`" alt=""> -->
                            </div>
                            <div class="friend-text">
                                <p>{{item.username}}</p>
                                <i class="fa-solid fa-circle" :class="{online: item.status}"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="inventory-container">
                    <div class="inventory-header">
                        <p>Envanterim</p>
                    </div>
                    <div class="inventory" v-if="user.inventory">
                        <!-- <div class="inventory-item" v-for="item, key, index in user.inventory" :key="index"> -->
                        <div class="inventory-item" v-for="index in 50" :key="index">
                            <div class="inventory-item-image" v-if="user.inventory[index - 1]">
                                <img :src="`https://assets.hypnosegames.com/images/item_images/${user.inventory[index - 1].name}.png`" alt="">
                            </div>
                            <div class="inventory-item-text">
                                <p>{{getItemName(index)}}</p>
                                <!-- <p>{{user.inventory[index] && user.inventory[index].amount}}</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>

<style lang="scss">
   .fade-enter-active {
        transition: opacity 3s ease-in-out;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-enter{
        opacity: 0;
    }

    .profile-main-container {
        display: flex;

        align-items: center;
        flex-direction: column;
        width: 100vw;


        .profile-popup-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100vw;
            height: 100vh;
            position: absolute;
            z-index: 999;
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(5px);
            .profile-popup {
                display: flex;
                flex-direction: column;
                width: 50vh;
                background: rgba(0, 0, 0, 0.9);
                padding: 3vh;
                gap: 1vh;

                position: relative;
                .close {
                    display: flex;
                    position: absolute;
                    right: 3vh;
                    i {
                        color: #FFC700;
                        font-size: calc(8px + 1vh);
                        cursor: pointer;
                    }
                }
                p {
                    text-align: center;
                }
                input {
                    width: 100%;
                    height: 4vh;
                    padding: 1vh;
                    border: none;
                    outline: none;
                    font-family: "Outfit-Medium";
                    font-size: calc(8px + 1vh);
                    background: transparent;
                    color: #FFFFFF;
                    border: 1px solid #FFC700;
                }
                .buttons {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1vh;
                    button {
                        width: 10vh;
                        height: 4vh;
                        border: none;
                        outline: none;
                        background: #FFC700;
                        color: #000000;
                        font-family: "Outfit-Medium";
                        font-size: calc(8px + 1vh);
                        cursor: pointer;
                        transition: all 200ms ease-in-out;
                    }
                    button:hover {
                        transform: scale(1.1);
                    }

                }
            }
        }

        .profile-center {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 1vh;
            flex-direction: column;

            .title {
                font-family: "Outfit-Bold";
                font-size: calc(18px + 1vh);
                font-style: normal;
                line-height: normal;
                color: #FFC700;
            }



            .profile-top-container {
                display: flex;
                flex-direction: column;
                gap: 1vh;

                background: url("https://assets.hypnosegames.com/images/profile-background.png");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;

                width: 85vw;
                height: 30vh;
                position: relative;
                // border: 1px solid red;
                border-bottom: 3px solid #FFC700;
                .background-picture-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    transition: all 250ms ease-in-out;
                    opacity: 0.0;
                    background: rgba(0, 0, 0, 0.55);
                    i {
                        font-size: 3vh;
                        color: #FFC700;
                    }
                }

                &:hover {
                    .background-picture-button {
                        opacity: 1.0;
                    }
                }
                .top-header {
                    display: flex;
                    width: 100%;
                    // border: 1px solid red;
                    justify-content: space-between;
                    padding: 1vh;
                    padding-inline: 1.5vw;

                    .trophy {
                        img {
                            object-fit: contain;
                            width: 5.5vh;
                            height: 5.5vh;
                        }
                    }

                    .search-bar {
                        display: flex;
                        align-items: center;
                        gap: 1vh;
                        background: rgba(0, 0, 0, 0.7);
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 1vh;
                        width: 24vh;
                        height: 4.5vh;
                        padding-inline: 1.5vh;
                        z-index: 99;
                        input {
                            height: 4vh;
                            background: transparent;
                            border: none;
                            outline: none;
                            font-family: "Outfit-Medium";
                            font-size: calc(8px + 1vh);
                            width: 100%;
                            color: #FFFFFF;
                        }
                        input ::placeholder {
                            color: rgba(255, 255, 255, 0.23);
                        }
                        i {
                            color: rgba(255, 199, 0, 1);
                            font-size: calc(6px + 1vh);
                        }
                    }

                }

                .profile-top-inner-container {
                    display: flex;
                    align-items: center;
                    gap: 2vh;
                    padding-inline: 1.5vw;

                    .image-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 14vh;
                        min-width: 14vh;
                        height: 14vh;
                        background: #FFFFFF;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 2px solid #FFC700;
                        position: relative;

                        cursor: pointer;
                        img {
                            object-fit: cover;
                            width: 100vw;
                            height: 100%;
                        }


                        .edit-picture-button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            z-index: 99;
                            transition: all 250ms ease-in-out;
                            opacity: 0.0;
                            background: rgba(0, 0, 0, 0.55);
                            border-radius: 50%;
                            i {
                                font-size: 3vh;
                                color: #FFC700;
                            }
                        }

                        &:hover {
                            .edit-picture-button {
                                opacity: 1.0;
                            }
                        }
                    }


                    .text-container {
                        .name {
                            display: flex;
                            align-items: center;
                            gap: 1vh;
                            img {
                                object-fit: contain;
                            }

                            i {
                                //red color
                                color : rgb(196, 2, 2);
                                font-size: calc(6px + 1vh);
                            }
                            .verified {
                                color: #FFC700 !important;
                            }
                        }
                        gap: 1vh;
                        p {
                            font-family: "Outfit-Medium";
                            font-size: calc(12px + 1vh);
                        }
                        p:nth-child(2) {
                            color: #FFC700;
                            font-size: calc(7px + 1vh);
                            text-transform: uppercase;
                        }
                        p:nth-child(3) {
                            font-family: "Outfit-Light";
                            font-style: normal;
                            line-height: normal;
                            color: #FFFFFF;
                            font-size: calc(5px + 1vh);
                        }

                        .add-friend-button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 4vh;
                            height: 4vh;
                            background: #FFC700;
                            overflow: hidden;
                            border-radius: 50%;
                            cursor: pointer;
                            margin-top: 1vh;
                            transition: all 150ms ease-in-out;
                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

                            i {
                                color: #FFFFFF;
                                font-size: calc(5px + 1vh);
                                transition: all 150ms ease-in-out;
                            }

                        }

                        .add-friend-button:hover {
                            background: #FFC700;
                            i {
                                color: #000000;
                            }
                        }
                    }

                }

            }

            .profile-middle-container {
                display: flex;
                gap: 1vh;
                width: 85%;
                height: 60vh;
                padding: 1vh;
                padding-inline: 1.5vw;
                justify-content: space-between;
                background: rgba(0, 0, 0, 0.31);
                .friend-list-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 1vh;

                    .friend-list-header {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1vh;
                        p {
                            font-family: "Outfit-Bold";
                            font-size: calc(12px + 1vh);
                            font-style: normal;
                            line-height: normal;
                            color: #FFC700;
                        }
                    }
                    .friend-list {
                        display: flex;
                        gap: 1vh;
                        width: 100%;
                        // border: 1px solid green;
                        height: 100%;
                        overflow: auto;
                        flex-wrap: wrap;
                        .friend {
                            display: flex;
                            align-items: center;
                            gap: 1.25vh;
                            padding: 2vh;
                            height: 10vh;
                            border-radius: 0.3125rem;
                            background: rgba(0, 0, 0, 0.31);
                            flex: 1 1 33%;
                            cursor: pointer;
                            .friend-image {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 6vh;
                                height: 6vh;
                                background: #FFFFFF;
                                overflow: hidden;
                                border-radius: 50%;
                                border: 2px solid #FFC700;
                                flex-shrink: 0;

                                img {
                                    object-fit: cover;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .friend-text {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 100%;
                                p {
                                    font-family: "Outfit-Medium";
                                    font-size: calc(8px + 1vh);
                                    font-style: normal;
                                    line-height: normal;
                                    color: #FFFFFF;
                                }
                                p:nth-child(2) {
                                    font-family: "Outfit-Light";
                                    font-style: normal;
                                    line-height: normal;
                                    color: #FFFFFF;
                                    font-size: calc(5px + 1vh);
                                }

                                i {
                                    color: #F22;
                                    font-size: calc(5px + 1vh);
                                }
                                .online {
                                    color: #61FF5E;
                                }
                            }
                        }
                    }
                }

                .inventory-container {
                    display: flex;
                    flex-direction: column;
                    gap: 1vh;


                    // border: 1px solid red;
                    .inventory-header {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1vh;
                        p {
                            font-family: "Outfit-Bold";
                            font-size: calc(12px + 1vh);
                            font-style: normal;
                            line-height: normal;
                            color: #FFC700;
                        }
                    }
                    .inventory {
                        display: flex;
                        gap: 1vh;
                        flex-wrap: wrap;
                        width: 40vw;
                        overflow: scroll;

                        .inventory-item {
                            display: flex;
                            width: 4vw;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;
                            gap: 1.5vh;
                            background: rgba(0, 0, 0, 0.4);
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                            flex: 1 1 30%;
                            height: 16vh;
                            border-bottom: 3px solid #FFC700;
                            .inventory-item-image {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 7.5vh;
                                height: 7.5vh;
                                overflow: hidden;
                                // border-radius: 50%;
                                // background: #FFFFFF;
                                // border: 2px solid #FFC700;
                                img {
                                    object-fit: contain;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .inventory-item-text {
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                gap: 1vh;
                                p {
                                    font-family: "Outfit-Medium";
                                    font-size: calc(10px + 0.390625vw);
                                    font-style: normal;
                                    line-height: normal;
                                    color: #FFFFFF;
                                }
                                p:nth-child(2) {
                                    font-family: "Outfit-Light";
                                    font-style: normal;
                                    line-height: normal;
                                    color: #FFFFFF;
                                    font-size: calc(5px + 1vh);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
<script>
import AuthService from '@/services/AuthService.js';
export default {
    data() {
        return {
            self: this.$store.getters.getUser,

            user: null,
            username: '',
            searchText: '',
            pictureLink: '',

            selectedUsername: '',
            editPicture: false,
        };
    },
    // async created() {
    //     await this.getDatas();
    // },

    async mounted() {
       await this.getDatas();
    },



    methods: {

        async getDatas() {
            if (this.searchText !== '') {
                this.selectedUsername = this.self?.username;
            } else if (this.$route.params.username) {
                this.selectedUsername = this.$route.params.username;
            } else {
                this.selectedUsername = this.$store.getters.getUser.username;
            }
            if (this.selectedUsername !== '' && this.selectedUsername !== this.user?.username) {
                var searchUser = await AuthService.fetchUser(this.selectedUsername);

                if (searchUser.error) {
                    this.$store.dispatch('addNotification', {
                        title: 'Arama başarısız.',
                        text: 'Aradığınız kullanıcı bulunamadı.',
                    });
                } else {
                    this.user = searchUser;
                }
                console.log(searchUser, searchUser.error)
            }
            console.log(114, this.selectedUsername, this.user)
        },
        getItemName(index) {
            var item = this.user.inventory[index - 1]
            if (item) {
                return item.label ? item.label : item.name;
            }
            return '';
        },

        async onClickFriend(username) {
            var searchUser = await AuthService.fetchUser(username);
            if (searchUser.error) {
                this.$store.dispatch('addNotification', {
                    title: 'Arama başarısız.',
                    text: 'Aradığınız kullanıcı bulunamadı.',
                });
            } else {
                this.user = searchUser;
                this.$router.push({name: 'userProfile', params: {username: username}});
            }

        },
        isAbleOpen() {
            return this.$store.getters.isLoggedIn && this.$store.getters.getUser.username === this.user?.username;
        },
        async savePicture() {

            if (this.pictureLink === '') {
                return;
            }

            if (this.editPicture === 'background') {
                this.$socket.emit('updateBackgroundPicture', {username: this.selectedUsername, backgroundPicture: this.pictureLink});
                this.user.backgroundPicture = this.pictureLink;
            } else {
                this.$socket.emit('updateProfilePicture', {username: this.selectedUsername, profilePicture: this.pictureLink});
                this.user.profilePicture = this.pictureLink;
            }
            this.editPicture = false;
            this.pictureLink = '';

        },
        calculateDate(user) {
            if (user) {
                const date = new Date(user.registered);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            }
            return `11.12.2023`;
        },
        async search() {
            var text = this.searchText
            if (text === '') {
                text = this.$route.params.username;
            }
            var searchUser = await AuthService.fetchUser(text);
            console.log(123, searchUser, searchUser.error)
            if (searchUser.error) {
                this.$store.dispatch('addNotification', {
                    title: 'Arama başarısız.',
                    text: 'Aradığınız kullanıcı bulunamadı.',
                });
            } else {
                this.user = searchUser;
            }
        },
        async addFriend() {
            var receiverUsername = this.searchText != '' ? this.searchText : this.$route.params.username;
            var selfUserData = this.$store.getters.getUser
            if (!selfUserData) {
                return;
            }
            // if (receiverUsername === selfUserData.username) {
            //     return;
            // }

            var senderUsername = selfUserData.username

            this.$socket.emit('friendRequest', {
                senderUsername: senderUsername, // Bu değeri kullanıcı oturumu açtığında alın veya gönder
                receiverUsername : receiverUsername,
            });
        }
    }
};
</script>