<template>
    <div class="about-container">
        <div class="about-header">
            <h1>ABOUT</h1> <h1>US</h1>
        </div>

        <div class="text-container">
            <div class="box">
                <div class="title">
                    <p>About Hypnose Game Studio</p>
                </div>
                <p>
                    Hello! We are the team behind Hypnose Game Studio, a group dedicated to infusing creativity and excitement into the gaming world. In our games, we don't just aim for entertainment; we strive to create unique and unforgettable experiences. we go beyond being merely a game studio; we are artisans crafting not just games, but works of art.
                </p>
            </div>
           
            <div class="box">
                <div class="title">
                    <p>A Team with Diverse Talent</p>
                </div>
                <p>
                    Get to know us and become a part of the diverse team at the heart of Hypnose Game Studio! Bringing together two seasond programmers, two skilled 3D Artist, an accomplished artist , and two marketing experts, we have united to work on projects that will leave a mark in the gaming industry. Additionally, a designer specializing in user interface (UI) and user experiance (UX) enhances our team's capabilities.
                </p>
            </div>
           
            <div class="box">
                <div class="title">
                    <p>Convergence of Creativity and Technology</p>
                </div>
                <p>
                    At Hypnose Game Studio, we make a unique contribution to the gaming world by merging creativity and technology. The visual aesthetics and technical intricacies in our projects aim not only to redefine our games but also to reshape the entire gaming landscape.
                </p>
            </div>
            
            <div class="box">
                <div class="title">
                    <p>Customer-Centric Approach</p>
                </div>
                <p>
                    We stand out by not only focusing on our games but also prioritizing you, our audience. With customer satisfaction at the core, we meticulously plan our projects and exert efforts to grow alongside you.
                </p>
            </div>

            <div class="box">
                <div class="title">
                    <p>Exciting Projects for the Future</p>
                </div>
                <p>
                    As Hypnose Game Studio, we are committed to bringing forth projects that will leave a mark not only in the gaming world but also in the realms of art and entertainment. Join us on this exciting journey, and together, let's push the boundaries of the gaming world!
                </p>
            </div>
           
           
            <div class="box">
                <div class="title">
                    <p>Academic Odyssey: A New Journey in Language Learning</p>
                </div>
                <p>
                    At Hypnose Game Studio, we redefine language learning from the ground up with the creativity and excitement we bring to the gaming world. Our inaugural project, "Academic Odyssey," transforms language learning into more than just an entertainment tool, turning it into a genuine adventure. This game offers users the opportunity to enhance their language skills through learning and exploration.
                </p>
            </div>

            <div class="box">
                <div class="title">
                    <p> What is Academic Odyssey?</p>
                </div>
                <p>
                    "Academic Odyssey" is an interactive adventure game that gamifies language learning, providing users with an immersive educational experience. Players encounter challenges at different language levels, improving their language skills while also having the chance to explore diverse cultures. The game makes the learning process enjoyable, boosting user motivation.
                </p>
            </div>

            <div class="box">
                <div class="title">
                    <p>Key Features of the Game:</p>
                </div>
                <p>
                    Fun and Interactive Learning: Academic Odyssey transforms language learning into a game, allowing users to learn interactively. Challenging tasks encourage users to practice their language skills.
                </p>
            </div>
           
            <div class="box">
                <div class="title">
                    <p>Realistic Lobbies</p>
                </div>
                <p>
                    Players navigate through realistic lobbies in Academic Odyssey, learning languages while experiencing different cultures. This approach aims to teach not only words but also the context in which the language is used.
                </p>
            </div>

            <div class="box">
                <div class="title">
                    <p>Community and Communication</p>
                </div>
                <p>
                    Through Academic Odyssey, players can make new friends and share language skills with others. This enriches the learning experience and encourages users to engage in real communication in the language.
                </p>
            </div>

            <div class="box">
                <div class="title">
                    <p>Embarking on a Journey to the Future with Academic Odyssey</p>
                </div>
                <p>
                    Academic Odyssey aims to make language learning an exciting journey, transcending the traditional classroom approach. As Hypnose Game Studio, we will continue to transform language learning with projects like Academic Odyssey. Join us on this unforgettable journey!
                </p>
            </div>

            Feel free to reach out with any questions or feedback. Thank you for choosing us!


            <div class="bottom-text">
                <p>Best regards,</p>
                <p> Hypnose Game Studio Team</p>
            </div>
            
            <img src="@/assets/images/banner.png" alt="">
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            
        };
    },
    
    methods: {
        
    }
};
</script>

<style lang="scss" scoped>

.about-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 100%;

    .about-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10vh;
        width: 100vw;
        gap: 1vh;

        h1 {
            font-size: 3rem;
            font-family: 'Outfit-ExtraBold';

        }

        h1:nth-child(2) {
            color: #FFC700;

        }
    }

    .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 80vw;
        background-color: rgba(0, 0, 0, 0.12);

        gap: 3.5vh;
        padding: 5vh;
        text-align: center;
        padding-top: 3vh;
        .title {
            p {
                font-size: calc(18px + 0.390625vw);
                font-weight: 700;
                color: #FFC700;
            }
        }
        .inline {
            color: #FFC700; 
            font-family: 'Outfit-Bold';
            a {
                font-size: calc(14px + 0.390625vw);
            }
        }
        p {
            font-size: calc(14px + 0.390625vw);
            font-weight: 400;
        }

        .bottom-text {
            display: flex;
            flex-direction: column;
            p {
                color: #FFC700;
                font-size: calc(14px + 0.390625vw);
                font-family: 'Outfit-ExtraBold';
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>