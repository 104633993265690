import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Axios from 'axios';

import io from 'socket.io-client';

// set auth header
Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;

import config from './config';

const socket = io(config.ip, {
  transports: ['websocket'],
  withCredentials: true,
  extraHeaders: {
    "Access-Control-Allow-Origin": "https://hypnosegames.com",
    "Authorization": `Bearer ${store.state.token}`
  }
});

store.$socket = socket;

createApp(App)
  .use(store)
  .use(router)
  .mixin({
    beforeCreate() {
      this.$socket = socket;
    },
  })
  .mount('#app');
