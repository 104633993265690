<template>
    <div class="login-container">
        <div class="login-center-container">
            <p class="title">GİRİŞ YAP</p>
            <div class="inline">
                <div class="column">
                    <p>Kullanıcı Adı</p>
                    <input type="text" placeholder="Kullanıcı Adı" v-model="username" />
                </div>
                <div class="column">
                    <p>Şifre</p>
                    <input type="password" placeholder="Şifre" v-model="password" />
                </div>
            </div>
            <div class="login-button" @click="login">GİRİŞ YAP</div>
            <p v-if="msg">{{ msg }}</p>
        </div>
    </div>
</template>

<style lang="scss">
    .login-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100vh;

        .login-center-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 2vh;
            padding: 3vh;
            background: rgba(0, 0, 0, 0.35);
            border-radius: 1vh;
            


            .inline {
                display: flex;
                flex-direction: column;
                gap: 1vh;

                .column {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5vh;
                }
            }
            .title {
                font-family: "Outfit-Bold";
                font-size: 3vh;
                color: #FFC700;
                text-align: center;
            }
            input {
                background: rgba(0, 0, 0, 0.35);
                outline: none;
                border: 0;
                padding: 1vh;
                color: #FFFFFF;
                font-family: "Outfit-Regular";
                width: 100%;
                font-size: 1.5vh;
            }

            .login-button {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #FFC700;
                border-radius: 1vh;
                padding: 1vh;
                font-family: "Outfit-Bold";
                font-size: 2vh;
                color: #000000;
                cursor: pointer;
                transition: background 150ms ease-in-out;
                text-align: center;
                &:hover {
                    background: #FFC700c7;
                }
            
            }
        }
    }

</style>

<script>
import AuthService from '@/services/AuthService.js';
export default {
    data() {
        return {
            username: '',
            password: '',
            msg: ''
        };
    },
    methods: {
        async login() {
            try {
                const credentials = {
                    username: this.username,
                    password: this.password
                };
                const response = await AuthService.login(credentials);
                console.log(123, response)
                this.msg = response.msg;
                const token = response.token;
                const user = response.user;
                this.$store.dispatch('login', { token, user });
                this.$store.dispatch('setLoggedIn', true);
                setTimeout(() => {
                    this.$router.push('/');
                    this.msg = ''
                }, 1500);
            } catch (error) {
                console.log(122 ,error)
                this.msg = error.response.data.msg;
                setTimeout(() => {
                    this.msg = ''
                }, 1500);
            }
        }
    },

    async created() {
        if (this.$store.getters.isLoggedIn) {
            this.$router.push('/');
        }
    },
};
</script>