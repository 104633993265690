<template>
    <div>
        <!-- <button @click="showToast('Başlık 1', 'Bildirim 1')">Bildirim 1</button>
        <button @click="showToast('Başlık 2', 'Bildirim 2')">Bildirim 2</button>
        <button @click="showToast('Başlık 3', 'Yeni bir arkadaşlık isteğin var!')">Bildirim 3</button> -->
        <div class="notifications-container">
            <transition-group name="notification-slide">
                <!-- <p>{{ notifications }}</p> -->
                <ToastNotification
                    v-for="(notification, index) in $store.state.notifications"
                    :key="index"
                    :notification="notification"
                    @hideNotification="hideNotification(index)"
                />
            </transition-group>
        </div>
    </div>
</template>

<script>
import ToastNotification from "@/components/ToastNotification.vue";

export default {
    components: {
        ToastNotification,
    },

    data() {
        return {
            timeInterval: null,
        };
    },
   
    methods: {
        hideNotification(index) {
            this.$store.commit('hideNotification', index);
        },
        showToast(title, text) {
            this.$store.dispatch('addNotification', {
                title: title,
                text: text,
                type: 'friendRequest',
                // senderUsername: notification.senderUsername,
                // receiverUsername: notification.receiverUsername,
            });

            // Bildirimi zaman aşımına göre kaldır
        },
    },

    mounted() {
        if (this.timeInterval) clearTimeout(this.timeInterval);
        this.timeInterval = setInterval(() => {
            this.$store.commit('timerNotification')
        }, 1000);
    },
    updated() {
        if (this.timeInterval) clearTimeout(this.timeInterval);
        this.timeInterval = setInterval(() => {
            this.$store.commit('timerNotification')
        }, 1000);
    },
};
</script>

<style scoped lang="scss">
.notifications-container {
    display: flex;
    gap: 1vh;
    justify-content: center;
    flex-direction: column-reverse; /* Alt alta sıralamak için flex-direction'ı değiştirin */
    position: fixed;
    top: 12.5vh;
    right: 3vh;
    z-index: 999;

    // border: 1px solid red;
}


.notification-slide-enter-active,
.notification-slide-leave-active {
  transition: transform 0.5s ease-in-out;
}

.notification-slide-enter,
.notification-slide-enter-from,
.notification-slide-leave-to {
  transform: translateX(100%);
}
</style>
