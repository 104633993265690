<template>
    <div class="home-container">
        <div class="center">
            <p>Hypnose</p>
            <p>Play, learn and fun.</p>
            
            <div class="photo-container">
               
                <img :key="selectedPage" :src="$store.state.homePages[selectedPage].image" @load="photoLoaded(selectedPage)"  alt="">
            </div>

            <div class="page-changer">
                <div class="dot" :key=key v-for="item, key in $store.state.homePages" :class="{selected: selectedPage == key}" @click="selectedPage = key"></div>
            </div>
        </div>

        <div class="bottom">
            <div class="games-container">
                <div class="game-buttons" :key=index v-for="item, key, index in $store.state.bottomPages">
                    <div class="game-button">
                        <img :src="item.image" alt="">
                        <div class="game-button-bottom">
                            <p>{{item.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
   .fade-enter-active {
        transition: opacity 3s ease-in-out;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-enter{
        opacity: 0;
    }

    .home-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100vw;
        .center {
            display: flex;
            flex-direction: column;
            width: 60vw;
            gap: 1vh;

            .photo-container {
                display: flex;
                flex-direction: column;

                width: 60vw;
                min-height: 35vw;
                position: relative;
                // border: 1px solid red;

                .loading-overlay {
                    background-color: #000000;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    // border: 1px solid red;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 9999;
                    position: absolute;
                    .loading-spinner {
                        border: 8px solid #242424;
                        border-top: 5px solid #FFC700;
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;
                        animation: spin 1s linear infinite;
                    }
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                }
                

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }

                img {
                    // border: 1px solid white;
                    border-radius: 0.5vh;
                    object-fit: contain;
                }

               
            }

            

          
            p {
                color: #FFFFFF;
                font-family: "Outfit-Bold";
            }

            p:nth-child(1) {
                font-size: 5vh;
                font-style: normal;
                line-height: normal;
                color: #FFC700;
            }
            p:nth-child(2) {
                font-size: 4vh;
                font-style: normal;
                line-height: normal;
                color: white;
            }

            .page-changer {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1vh;
                transition: opacity 150ms ease-in-out;
                padding-bottom: 2vh;
                margin-top: 1vh;
                .dot {
                    width: 1.25vh;
                    height: 1.25vh;
                    border-radius: 50%;
                    background-color: #535353;
                    cursor: pointer;
                    transition: background-color 250ms ease-in-out;

                }
                .selected {
                    background-color: #FFC700;
                }
            }
        }

        .bottom {
            // display: flex;
            // justify-content: center;
            // align-items: center;
            width: 100vw;
            display: flex;
            flex-direction: column;
            margin-top: auto;
            // border: 1px solid red;
            .games-container {
                width: 100vw;
                display: flex;
                flex-wrap: wrap;
                padding: 2.5vh;
                justify-content: center;
                align-items: center;
                .game-buttons {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    // width: 20vw;
                    // height: 10vw;
                    padding: 1vh;       

                    // border: 1px solid red;
                    .game-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        border-bottom: 3px solid #FFC700;

                        img {
                            width: 25vw;
                            object-fit: contain;
                            border-radius: 0.5vh;

                        }
                        .game-button-bottom {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            z-index: 2;
                            width: 100%;
                            bottom: 0;
                            background: rgba(0, 0, 0, 0.53);
                            padding: 1.25vh;  
                            height: 2vw;
                            border-radius: 0.5vh;
                            white-space: nowrap;

                            p {
                                color: #FFFFFF;
                                font-family: 'Outfit-Medium';
                                font-size: 1.5vh;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
<script>
export default {
    data() {
        return {
            secretMessage: '',

            loaded : false,
            selectedPage: 0,


        };
    },

    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.push('/login');
        },
        photoLoaded(page){
            this.$store.commit('setPhotoLoaded', { page, loaded: true });
        }
    },


    created() {
        // Set an interval to change the selectedPage every 5 seconds
        this.pageChangeInterval = setInterval(() => {
            const pageCount = Object.keys(this.$store.state.homePages).length;
            this.selectedPage = (this.selectedPage + 1) % pageCount;
        }, 5000);
    },

    destroyed() {
        // Clear the interval when the component is destroyed
        clearInterval(this.pageChangeInterval);
    },
};
</script>