<template>
    <div class="container">
        <notifications></notifications>
        <transition name="fade">
            <div v-if="isLoading" id="loading-screen">
                <div id="loading-spinner"></div>
            </div>
        </transition>

        <div class="background-image"></div>
        <div class="transparent"></div>

        <transition-group name="notification-slide">
            <div class="general-notifications-container" v-if="generalNotify" key="notification">
                <p class="header">BILDIRIMLER</p>

                <div class="empty-notification" v-if="generalNotifications.length <= 0">
                    <i class="fas fa-sad-cry"></i>
                    <p>Hiç bildirimin yok...</p>
                </div>
                <div class="general-notification" v-else :key=key v-for="item, key in generalNotifications">
                    <!-- <div class="notification-header">
                        <p>{{item.title}}</p>
                    </div> -->
                    <div class="notification-text">
                        <div class="image-circle" v-if="item.senderProfilePicture">
                            <img :src="item.senderProfilePicture" alt="logo">
                        </div>
                        <p>{{item.message}}</p>
                    </div>

                    <div class="buttons" v-if="item.type == 'friendRequest'">
                        <div class="button accept" @click="acceptFriendRequest(item)">
                            <p>Kabul Et</p>
                        </div>

                        <div class="button deny" @click="declineFriendRequest(item)">
                            <p>Reddet</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="general-news-container" v-else-if="newsTab" key="news">
                <p class="header">CHANGELOG</p>

                <div class="empty-notification" v-if="newsTabData.length <= 0">
                    <i class="fas fa-sad-cry"></i>
                    <p>Hiç güncelleme yok...</p>
                </div>
                <div class="general-notification" v-else :key=key v-for="item, key in newsTabData">
                    
                    <div class="notification-text">
                        <div class="image-circle" v-if="item.image">
                            <img :src="item.image" alt="logo">
                        </div>
                        <div class="texts">
                            <p>{{item.title}}</p>
                            <p>{{item.text}}</p>
                        </div>
                    </div>

                    <div class="buttons" v-if="item.type == 'friendRequest'">
                        <div class="button accept" @click="acceptFriendRequest(item)">
                            <p>Kabul Et</p>
                        </div>

                        <div class="button deny" @click="declineFriendRequest(item)">
                            <p>Reddet</p>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
       
            
        <div class="verify-mail" v-if="this.mailVerifyNotify">
            <div class="verify-container">
                <p>Görünüşe göre e-postanızı hala onaylamamışsınız.</p>
                <p>E-Posta onayınızı mail üzerinden bulabilirsiniz.</p>

                <div class="verify-button" @click="sendMailVerify()">
                    <p>Tekrar gönder</p>
                </div>

                <p class="error" v-if="this.mailVerifyError">{{ this.mailVerifyError }}</p>

                <div class="close-button" @click="this.mailVerifyNotify = false">
                    <i class="fas fa-times"></i>
                </div>
            </div>
        </div>

        <nav>
            <div class="logo">
                <router-link to="/">
                    <img src="@/assets/images/logo.png" alt="logo">
                </router-link>
                
            </div>
            <div class="buttons">
                <router-link to="/">ANASAYFA</router-link>
                <router-link v-if="!this.$store.state.isLoggedIn" to="/sign-up">KAYIT OL</router-link>
                <router-link v-if="!this.$store.state.isLoggedIn" to="/login">GİRİŞ YAP</router-link>
                <router-link v-if="this.$store.state.isLoggedIn && this.username != ''" :to="{ name: 'userProfile', params: { username: this.username }}" @click="refreshPage">PROFIL</router-link>
                <a v-if="this.$store.state.isLoggedIn" @click="logout">ÇIKIŞ YAP</a>
                <i class="fa-solid fa-newspaper" @click="newsTab = !newsTab; generalNotify = false" :class="{ active: newsTab }"></i>
                <i class="fas fa-bell" v-if="this.$store.state.isLoggedIn" @click="generalNotify = !generalNotify; newsTab = false" :class="{ active: generalNotify }"></i>
            </div>
        </nav>

        <router-view class="router-class"/>

        <footer>
            <div class="logo-part">
                <img src="@/assets/images/logo.png" alt="logo">
                <p>COPYRIGHT ALL RIGHTS RESERVED</p>
            </div>

            
            <div class="links">
                <div class="link">
                    <router-link to="/about">ABOUT US</router-link>
                </div>
                <div class="link">
                    <router-link to="/contact">CONTACT</router-link>
                </div>
            </div>

            <div class="links">
                <div class="link">
                    <p>PRIVACY POLICY</p>
                </div>
                <div class="link">
                    <p>TERMS OF USE</p>
                </div>
            </div>
          
            <div class="media-links">
                <div class="media-link">
                    <i class="fa-brands fa-discord"></i>
                    <a href="https://discord.gg/YRUh42XkfR" target="_blank">DISCORD</a>
                </div>

                <div class="media-link">
                    <i class="fa-brands fa-instagram"></i>
                    <a href="https://instagram.com/hypnosegamestudio/" target="_blank">INSTAGRAM</a>
                </div>
                <div class="media-link">
                    <i class="fa-brands fa-linkedin"></i>
                    <a href="https://linkedin.com/company/hypnosegamestudio/" target="_blank">LinkedIn </a>
                </div>

            </div>
        </footer>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import notifications from '@/components/NotificationContainer.vue';
export default {
    components: {
        notifications
    },
    data() {
        return {
            isLoading: true,

            username: '',
            mail: '',
            isLoggedIn : false,
            mailVerifyNotify : false,
            mailVerified : false,

            mailVerifyError: '',

            generalNotify: false,
            generalNotifications: [],

            newsTab: false,
            newsTabData: [],
        };
    },
    // async created() {
    //     console.log('app vue created')

    //     await this.checkDatas();
    // },



    watch : {
        '$store.state.isLoggedIn' : function() {
            console.log('app vue watch isLoggedIn')

            this.checkDatas()
        }
    },

    async mounted() {
        console.log('app vue mounted')
        setTimeout(() => {
            this.isLoading = false; // Yükleme tamamlandığında loading ekranını gizler
        }, 750); // Örneğin, 2000 milisaniye (2 saniye) süreyle loading ekranını gösterir
        
        
        this.$socket.on('notification', (notification) => {
            // this.$store.dispatch('login', { token, user });
            this.$store.dispatch('addNotification', notification);
        });   
        this.$socket.on('disconnect', () => {
            window.location.reload();
        });

        await this.checkDatas();
    },

   
    updated() {
        console.log('app vue updated')
        this.checkDatas()
    },
    methods: {
        async checkDatas() {
            if (this.$store.getters.isLoggedIn) {
                this.$store.dispatch('setLoggedIn', true);
            }
            var userData = this.$store.getters.getUser
            console.log(1231233, userData)
            if (userData != null) {
                this.username = userData.username;
                if (this.username != null) {
                   
                    this.mailVerified = userData.isVerified == 1;
                    this.mail = userData.email;

                    this.$socket.emit('setData', this.username);

                    this.$socket.emit('getNotifications', this.username);
                    
                    this.$socket.on('serverNotifications', (notifications) => {
                        this.generalNotifications = notifications;
                    });
                }
                
            }

            this.newsTabData = await AuthService.getNewsData();

            if (this.$store.state.isLoggedIn && !this.mailVerified) {
                this.mailVerifyNotify = true
            }
        },
        acceptFriendRequest(item) {
            this.$socket.emit('acceptFriendRequest', item);
        },
        declineFriendRequest(item) {
            this.$socket.emit('rejectFriendRequest', item);
        },
        refreshPage() {
            console.log('refresh', this.username, this.$route.name)
            if (this.$route.name == 'userProfile') {
                this.$router.push({ name: 'userProfile', params: { username: this.username } });
                setTimeout(() => {
                    window.location.reload();
                }, 125);
            }
        },
        logout() {
            this.$store.dispatch('logout');
            this.$router.push('/login');

            this.$socket.emit('logout', this.username);

            this.$store.dispatch('setLoggedIn', false);
            this.mailVerifyNotify = false;
        },
        async sendMailVerify() {

            if (this.mailVerified || this.mail == '') {
                return
            }

            //send verify mail
            const verifyData = await AuthService.sendVerifyMail(this.mail)
            if (verifyData && verifyData.msg) {
                this.mailVerifyError = verifyData.msg
                if (verifyData.success) {
                    var userData = { ...this.$store.getters.getUser };
                    userData.isVerified = 1;
                    this.$store.commit('SET_USER', userData);
                    this.mailVerified = true
                    console.log(31, verifyData)
                }
            }

           
        }
    }
};
</script>

<style lang="scss">

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");*/
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css");
@import url("https://cdn.x99studio.com/fqx/fontawesome-pro.css"); 

@font-face {
  font-family: "Outfit-Black";
  src: url("assets/fonts/Outfit-Black.ttf") format("woff");
}
@font-face {
  font-family: "Outfit-Bold";
  src: url("assets/fonts/Outfit-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Outfit-ExtraBold";
  src: url("assets/fonts/Outfit-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Outfit-ExtraLight";
  src: url("assets/fonts/Outfit-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Outfit-Light";
  src: url("assets/fonts/Outfit-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Outfit-Medium";
  src: url("assets/fonts/Outfit-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Outfit-Regular";
  src: url("assets/fonts/Outfit-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Outfit-SemiBold";
  src: url("assets/fonts/Outfit-SemiBold.ttf") format("truetype");
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */ /* IE 10+ */
    user-select: none; /* Likely future */
}

body {
    font-family: "Outfit-Regular";
    /* overflow-x: hidden; */
    background: #242424;
    color: #FFFFFF;
}

.notification-slide-enter-active,
.notification-slide-leave-active {
  transition: transform 0.5s ease-in-out;
}

.notification-slide-enter,
.notification-slide-enter-from,
.notification-slide-leave-to {
  transform: translateX(100%);
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-image: url("assets/images/home-background.png");
    background-size: 100%;
    #loading-screen {
        background-color: #000000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        #loading-spinner {
            border: 8px solid #242424;
            border-top: 5px solid #FFC700;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            animation: spin 1s linear infinite;
        }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    }

    // .background-image {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    //     position: absolute;
       
    //     z-index: -1;
    //     background-color: #242424;
    //     // filter: blur(10px);
    // }
    .transparent {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }

    .general-notifications-container {
        right: 20px;
        z-index: 9998;
        height: 100vh;
        background: #000000;
        position: absolute;
        top: 12.5vh;
        border: 2px solid #FFC700;
        min-width: 40vh;
        max-width: 40vh;
        overflow: auto;

        border-radius: 2vh;

        // min-width: 20vw;
        // max-width: 20vw;

        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Outfit-Bold";
            font-size: calc(12px + 0.390625vw);
            color: #FFC700;
            padding: 2vh;
            padding-inline: 2vw;
            border-bottom: 1px solid #FFC700;
        }

        .empty-notification {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-family: "Outfit-Medium";
            font-size: calc(10px + 0.390625vw);
            padding: 1vh;
            padding-inline: 2vw;
            gap: 1vh;
            height: 80%;

            i {
                font-size: calc(36px + 0.390625vw);
                color: #FFC700;
            }
        }
        .general-notification {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 1vh;
            border-bottom: 1px solid #FFC700;

            .notification-header {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Outfit-Bold";
                font-size: calc(12px + 0.390625vw);
                color: #FFC700;
                padding: 1vh;
                padding-inline: 2vw;
            }

            .notification-text {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Outfit-Medium";
                font-size: calc(10px + 0.390625vw);
                padding: 2vh;
                padding-inline: 1vw;
                gap: 1vh;

                .image-circle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 6.5vh;
                    height: 5vh;
                    border-radius: 50%;
                    border: 2px solid #FFC700;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1vh;
                padding: 1vh;
                width: 100%;
                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1vh;
                    background: #FFC700;
                    cursor: pointer;

                    transition: 0.25s;
                    border-radius: 1vh;
                    p {
                        color: #FFFFFF;
                        font-size: calc(8px + 0.390625vw);
                        text-transform: uppercase;

                        font-family: "Outfit-Bold";
                    }
                }
                .deny {
                    background: #FF0000;
                }
                .button:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    .general-news-container {
        right: 20px;
        z-index: 9998;
        height: 100vh;
        background: #000000;
        position: absolute;
        top: 12.5vh;
        border: 2px solid #FFC700;
        min-width: 40vh;
        max-width: 40vh;
        overflow: auto;


        border-radius: 2vh;

        // min-width: 20vw;
        // max-width: 20vw;

        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Outfit-Bold";
            font-size: calc(12px + 0.390625vw);
            color: #FFC700;
            padding: 2vh;
            padding-inline: 2vw;
            border-bottom: 1px solid #FFC700;
        }

        .empty-notification {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-family: "Outfit-Medium";
            font-size: calc(10px + 0.390625vw);
            padding: 1vh;
            padding-inline: 2vw;
            gap: 1vh;
            height: 80%;

            i {
                font-size: calc(36px + 0.390625vw);
                color: #FFC700;
            }
        }
        .general-notification {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0.35vh;
            border-bottom: 1px solid #FFC700;

            .notification-text {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Outfit-Medium";
                font-size: calc(7px + 0.390625vw);
                padding: 2vh;
                padding-inline: 1vw;
                gap: 1vh;

                .texts {
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                    p:nth-child(1) {
                        font-family: "Outfit-Bold";
                        font-size: calc(8px + 0.390625vw);
                        color: #FFC700;
                    }

                    p:nth-child(2) {
                        font-family: "Outfit-Medium";
                        font-size: calc(7px + 0.390625vw);
                        color: #FFFFFF;
                    }
                }

                .image-circle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 7vh;
                    height: 7vh;
                    border-radius: 50%;
                    border: 2px solid #FFC700;
                    overflow: hidden;
                    flex-shrink: 0;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1vh;
                padding: 1vh;
                width: 100%;
                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1vh;
                    background: #FFC700;
                    cursor: pointer;

                    transition: 0.25s;
                    border-radius: 1vh;
                    p {
                        color: #FFFFFF;
                        font-size: calc(8px + 0.390625vw);
                        text-transform: uppercase;

                        font-family: "Outfit-Bold";
                    }
                }
                .deny {
                    background: #FF0000;
                }
                .button:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    .verify-mail {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 999;

        .verify-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;

            border-radius: 0.3vh;
            background-color: rgba(0, 0, 0, 0.85);
            padding: 7vh;
            box-shadow: 0px 4px 0px 0px #FFC700;

            p:nth-child(1) {
                font-size: calc(24px + 0.390625vw);
                color: crimson;
            }

            .verify-button {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1vh;
                background: #FFC700;
                margin-top: 2vh;
                cursor: pointer;

                transition: 0.25s;
                p {
                    color: #FFFFFF;
                    font-size: calc(12px + 0.390625vw);
                    text-transform: uppercase;

                    font-family: "Outfit-Bold";
                }

               

            }
            .verify-button:hover {
                transform: scale(1.05);
            }

            .error {
                color: rgb(20, 220, 160);
                font-size: calc(10px + 0.390625vw);
                font-family: "Outfit-Medium";
                margin-top: 2vh;
            }

            .close-button {
                position: absolute;

                top: 1vh;
                right: 1vh;
                cursor: pointer;
                i {
                    color: #FFFFFF;
                    font-size: calc(12px + 0.390625vw);
                    transition: 0.25s;
                }

                i:hover {
                    color: #FFC700;
                }
            }
        }
    }
    
    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        height: 10vh;
        padding: 1vh;
        padding-bottom: 0;
        padding-inline: 3vw;
        .logo {
            img {
                width: 10vw;
                min-width: 10vh;
                height: 9vh;
                object-fit: contain;
            }
        }

        .buttons {
            display: flex;
            align-items: center;
            gap: 2vh;
            flex-grow: 0;

            a {
                cursor: pointer;
                text-decoration: none;
                color: #ffc800c7;
                font-family: "Outfit-SemiBold";
                font-size: calc(10px + 0.390625vw);
                font-style: normal;
                line-height: normal;
                transition: 0.25s;
                padding: 0.75vh;
                padding-inline: 0.7vw;
                border-radius: 0.35vh;
                white-space: nowrap;
            }
            a:hover {
                background: rgba(255, 199, 0, 1);
                color: #000000;
            }
            i {
                font-size: 2.5vh;
                color: #FFFFFF;
                transition: 0.25s;
                border-radius: 50%;
                padding: 0.8vh;
            }
            i:hover, i.active {
                color: #000000;
                background: rgba(255, 199, 0, 1);
            }
            .enabled {
                background: rgba(255, 199, 0, 1);
                color: #000000;
            }
        }
    }

    nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    nav .buttons a.router-link-exact-active {
        // color: #42b983;
        font-family: "Outfit-ExtraBold";

        background: rgba(255, 199, 0, 1);
        color: #000000;
    }


    .router-class {
        margin-bottom: 2vh;
    }
    

    footer {
        display: flex;
        justify-content: space-around;
        width: 100vw;
        background: #080707;
        padding: 3vh;
        padding-inline: 2vw;

        border-radius: 0.5vh;
        margin-top: auto;
        .logo-part {
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
                font-family: "Outfit-Medium";
                color: #FFFFFF;
                font-size: calc(7px + 0.390625vw);
            }
        }
        img {
            object-fit: contain;
            width: 15vw;
        }

        .links {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1vh;
            .link {
                display: flex;
                align-items: center;
                gap: 1vh;
                cursor: pointer;
                p {
                    font-family: "Outfit-Medium";
                    color: #FFFFFF;
                    transition: 0.3s;
                    font-size: calc(10px + 0.390625vw);

                    //responsive font size
                    @media screen and (max-width: 768px) {
                        font-size: calc(7px + 0.390625vw);
                    }
                    @media screen and (max-width: 480px) {
                        font-size: calc(6px + 0.390625vw);
                    }

                }

                a {
                    font-family: "Outfit-Medium";
                    text-decoration: none;
                    color: #FFFFFF;
                    transition: 0.3s;
                    font-size: calc(10px + 0.390625vw);

                    //responsive font size
                    @media screen and (max-width: 768px) {
                        font-size: calc(7px + 0.390625vw);
                    }
                    @media screen and (max-width: 480px) {
                        font-size: calc(6px + 0.390625vw);
                    }
                }
            }
            .link:hover {
                p, a {
                    color: #FFC700;
                }
            }
        }

        

        .media-links {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 1vh;
            cursor: pointer;
           

            .media-link {
                display: flex;
                align-items: center;
                gap: 1vh;

                i, a {
                    transition: 0.3s;
                }
                i {
                    font-size: calc(10px + 0.390625vw);
                    color: #FFFFFF;
                }
                a {
                    font-family: "Outfit-Bold";
                    text-decoration: none;
                    color: #FFFFFF;
                    font-size: calc(8px + 0.390625vw);

                }
            }

            

            .media-link:hover {
                i {
                    color: #FFC700;
                }
                a {
                    color: #FFC700;
                }
            }
        }

       
    }
}

//scrollbar
::-webkit-scrollbar {
    width: 0.3vw;
    height: 0.3vw;
}

//color it
::-webkit-scrollbar-thumb {
    background: #FFC700;
    border-radius: 0.7vw;
}

//background on hover
::-webkit-scrollbar-thumb:hover {
    background: #FFC700;
}

//background on active
::-webkit-scrollbar-thumb:active {
    background: #FFC700;
}

//background on drag
::-webkit-scrollbar-thumb:drag {
    background: #FFC700;
}


</style>
