import { createStore } from 'vuex'
import Axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

const getDefaultState = () => {
  return {
        isLoggedIn: false,
        notifications: [],
        
        token: '',
        user: {},

        groups: {
            'user' : 'Kullanıcı',
            'admin' : 'Yönetici',
            'mod' : 'Moderatör',
        },

        items: [],
        homePages: [
            // {
            //     id: 0,
            //     image: "https://assets.hypnosegames.com/images/game-images/image_1.png",
            // },
            {
                id: 0,
                image: "https://assets.hypnosegames.com/images/game-images/image_2.png",
            },
            {
                id: 1,
                image: "https://assets.hypnosegames.com/images/game-images/image_3.jpg",
            },
            {
                id: 2,
                image: "https://assets.hypnosegames.com/images/game-images/image_4.jpg",
            }
        ],

        bottomPages: [
            {
                id: 0,
                name: "Academic Odyssey",
                image: "https://assets.hypnosegames.com/images/game-images/image_1.png",
            },
            {
                id: 1,
                name: "Game 1",
                image: "https://assets.hypnosegames.com/images/game-images/no_game.png",
            },
            {
                id: 2,
                name: "Game 2",
                image: "https://assets.hypnosegames.com/images/game-images/no_game.png",
            },
            {
                id: 3,
                name: "Game 3",
                image: "https://assets.hypnosegames.com/images/game-images/no_game.png",
            },
            {
                id: 4,
                name: "Game 4",
                image: "https://assets.hypnosegames.com/images/game-images/no_game.png",
            },
            {
                id: 5,
                name: "Game 5",
                image: "https://assets.hypnosegames.com/images/game-images/no_game.png",
            },
        ],
    };
};

export default createStore({
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        isLoggedIn: state => {
            return state.token;
        },
        getUser: state => {
            return state.user;
        }
    },
    mutations: {
        addNotification(state, notification) {
            state.notifications.push(notification);
        },
        removeNotification(state, index) {
            state.notifications.splice(index, 1);
        },

        updateNotificationProgress(state) {
            state.notifications.forEach((notification) => {
                const duration = notification.duration;
                const decreaseAmount = 1 / duration; // Her saniyede azaltılacak miktar
        
                // Her saniyede bir azalt
                notification.progress -= decreaseAmount;
        
                // progress değerini 0 ile sınırla
                notification.progress = Math.max(notification.progress, -1);
        
                console.log(notification.progress, duration);
        
                // Bildirim süresi dolduğunda otomatik olarak kapat
                if (notification.progress < 0) {
                    const index = state.notifications.indexOf(notification);
                    if (index !== -1) {
                        state.notifications.splice(index, 1);
                    }
                }
            });
        },

        timerNotification(state) {
                
            state.notifications = state.notifications.filter((notification) => {
                if (notification) {
                    notification.duration -= 1;
                
                
                    // Bildirim süreci tamamlandığında kaldır
                    return notification.duration > 0;
                }
              
                return false;
            });
        },

        hideNotification(state, index) {
            state.notifications.splice(index, 1);
        },



        SET_LOGGED: (state, bool) => {
            state.isLoggedIn = bool;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_ITEMS : (state, items) => {
            state.items = items;
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
        RESET: state => {
            Object.assign(state, getDefaultState());
        },
        setPhotoLoaded(state, payload) {
            const { page, loaded } = payload;
            state.homePages[page].loaded = loaded;
        }
    },
    actions: {
        addNotification({ commit, state }, notification) {
            notification.id = state.notifications.length + Math.random();
            notification.duration = notification.duration || 5; // Varsayılan süre 5 saniye
            notification.progress = notification.duration
            commit('addNotification', notification);
        },

        setLoggedIn: ({ commit }, bool) => {
            commit('SET_LOGGED', bool);
        },

        login: ({ commit, dispatch }, { token, user }) => {
            commit('SET_TOKEN', token);
            commit('SET_USER', user);
            // set auth header
            Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },
        logout: ({ commit }) => {
            commit('RESET', '');
        },
        setUser : ({ commit }, user) => {
            commit('SET_USER', user);
        },
       
    },
    modules: {
    }
})