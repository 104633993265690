// src/services/AuthService.js
import axios from 'axios';
import config from '@/config.js';
const url = config.ip + '/api/';
console.log(url)

export default {
  login(credentials) {
    return axios
      .post(url + 'login/', credentials)
      .then(response => response.data);
  },
  signUp(credentials) {
    return axios
      .post(url + 'sign-up/', credentials)
      .then(response => response.data);
  },
  fetchUser(username) {
    return axios.get(url + `profile/${username}`).then(response => response.data);
  },

  sendVerifyMail(email) {
    return axios
      .post(url + 'send-verify-email/', {email: email})
      .then(response => response.data);
  },
  getSecretContent() {
    return axios.get(url + 'secret-route/').then(response => response.data);
  },

  getNotifications() {
    return axios.get(url + 'notifications/').then(response => response.data);
  },

  getNewsData() {
    return axios.get(url + 'getNews/').then(response => response.data);
  }
  
};