<template>
    <div class="toast-notification" :class="{ 'show': visible }" v-if="notification">
        <div class="texts">
            <h4>{{ notification.title }}</h4>
            <p>{{ notification.text }}</p>
        </div>
 
        <div class="progress-bar" :class="getAnimationClass()"></div>
    </div>
</template>

<script>
export default {
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            visible: true,
            timer: null,
        };
    },    

    methods: {
    getAnimationClass() {
        // Süreyi ve animasyon adını döndür
        const duration = this.notification.duration || 5; // Varsayılan süre 5 saniye
        const animationName = `prog-${duration}s`;
        return animationName;
    },
  },
};
</script>

<style scoped lang="scss">
.toast-notification {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    background-color: rgba(0, 0, 0, 0.733);
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 999;
    padding: 3vh;

    min-width: 20vh;
    min-height: 12vh;
    
    .texts {
        p:nth-child(2) {
            font-size: 0.8rem;
            color: rgb(196, 194, 194);
        }
    }
    .progress-bar {
        height: 3px;
        background-color: #FFC700;
        width: 100%;
        transition: width 0.1s linear;
        animation: prog 5s linear forwards;
        border-radius: 5px;

    }

    @keyframes prog {
        0% {
            width: 0;
        }
        100% {
            width: 100%;
        }
    }
}



.toast-notification.show {
  opacity: 1;
}


</style>
